import React, { useState,useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import Paragraph from '../typography/Paragraph';
import Number from '../typography/Number';
import photoTest from '../../assets/images/photo-start.jpg';
import TextPhoto from '../typography/TextPhoto';
import ScrollImg from '../../assets/scroll-home.svg';

import { useAppSelector, useAppDispatch } from '../../store/hooks';
import ContactInfo from '../typography/ContactInfo';
import Instagram from '../../assets/instagram.svg';
import Facebook from '../../assets/facebook.svg';
import Linkedin from '../../assets/linkedin.svg';
import Typewriter from 'typewriter-effect';

const ModuleWrapper = styled.div`
  overflow: hidden;
  background-color: white;
  z-index: 102;
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 4fr 3fr;
  position: relative;
  @media (max-width: 1440px) {
    grid-template-columns: 6fr 3fr;
  }
  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  @media (max-width: 550px) {
    min-height: 100vh;
    height: auto;
  }
`;

const TextWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 15vh 80px 0;
  display: flex;
  justify-content: flex-start;
  overflow-y: auto;
  flex-direction: column;
  overflow-x:hidden;

  @media (max-width: 1440px) {
    
  }

  @media (max-width: 1200px) {
    padding: 15vh 20vw 10vh;
  }
  @media (max-width: 550px) {
    padding: 17vh 30px 10vh;
  }
`;

interface PhotoWrapperProps {
  bgImage: string;
}

const PhotoWrapper = styled.div<PhotoWrapperProps>`
  width: 100%;
  height: 100%;
  background: black;
  background-size: cover;
  transition: all 0.3s ease-in-out;
  background-image: url('${({ bgImage }) => bgImage}');
  min-height: 200px;
  @media (max-width: 1200px) {
    max-height: 300px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
  }
  /* &:hover{
    div{
      opacity:1;
    }
  } */
`;

const TextBottom = styled.p`
  font-family: 'Staatliches';
  position: relative;
  margin:50px 0;
  font-size: 200px;
  line-height: 200px;
  /* bottom: -35px; */
  left: -88px;
  color: black;
  width: 100%;
  transition: all 0.3 ease-in-out;
  display: inline-block;
  width: max-content;
  @media (max-width: 1600px) {
    font-size: 141px;
    line-height: 124px;
    bottom: -16px;
  }
  @media (max-width: 1200px) {
    bottom: -13px;
    left: -21vw;
    /* color: white; */
  }
  @media (max-width: 550px) {
    font-size: 75px;
    line-height: 66px;
    position: relative;
    color: black;
    bottom: -8px;
    left: 0px;
  }

  &:hover {
    /* transform: matrix(-1, 0, 0, 1, 0, 0); */
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;

  }
`;

const Line = styled.div`
  width: 5px;
  height: 20vh;
  background-color: ${({ theme }) => theme.colors.blue};
  right: 0;
  position: absolute;
  top: 80vh;
`;

const StepWrapper = styled.div`
display:flex;
align-items:center;
justify-content: flex-start;
margin-top:28px;
width:80%;
@media (max-width:1440px){
    width:100%;
}
`

const SocialMediaWrapper = styled.div`
display:grid;
align-items:center;
justify-content: flex-start;
grid-gap:30px;
grid-template-columns: 1fr 1fr 1fr;
grid-template-rows: 1fr;
width: max-content;
cursor:pointer;
margin-bottom:100px;
@media (max-width:1200px){
    margin-bottom:0px;
}


`

const SocialMediaItem = styled.div`
width:24px;
height: 24px;
`

const SocialMediaIcon = styled.img`
transition: all 0.2s;
width:24px;
height:24px;
&:hover{
    transform:scale(1.05);
}
`

const typing = keyframes`
  from {
    width:0;
  }

  to {
    width:100%;
  }
`;





const TypeWritterText = styled.div`
    display: inline;
    text-decoration: underline;
    color:white;
    height: auto;
    font-weight: ${({ theme }) => theme.fontWeight.extraLight};
    font-family: 'Barlow', 'Oswald';
    text-align: left;
    transition: text-align 0.3s ease-in-out;
    font-size:90px;
    line-height: 94px;
    @media (max-width: 1440px) {
      font-size: 72px;
      line-height: 80px;
    }
`

const ScrollIcon = styled.img`
  margin: 0 auto;
  margin-top:32px;
  width: 32px;
  height: 20px;

`;


const ScrollText = styled.p`
  font-size: 12px;
  line-height: 30px;
  font-family: 'Barlow';
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  margin-top: 8px;
`;

const ScrollWrapper = styled.div`
  cursor:pointer;
  bottom: 2%;
  left: 0%;
  width:58%;
  height: 50%;
  position: absolute;
  display: flex;
  justify-content: flex-end;
  text-align: center;
  flex-direction: column;
  align-items: center;
  opacity:0;
  transition: all 0.2s;
  &:hover{
    opacity:1;
  }
`

interface ComponentProps {
  data: any;
  homeData:any,
}

const LetsStart: React.FC<ComponentProps> = ({ data,homeData }) => {

  const header = data?.header || '';
  const photo = data?.photo?.url || '';
  const photoText = data?.photoText || '';
  const textBottom = data?.textBottom || '';
  const textBottomHover = data?.textBottomHover || '';
  const facebookUrl = data?.facebookUrl || "";
  const linkedinUrl = data?.linkedinUrl || "";
  const instagramUrl = data?.instagramUrl || "";
  const mail = data?.mail || '';
  const mailInfo = data?.mailInfo || '';
  const phone = data?.phone || '';
  const phoneInfo = data?.phoneInfo || '';
  const website = data?.website || '';
  const websiteInfo = data?.websiteInfo || '';
  const socialInfo = data?.socialInfo || '';
  const stepOne = data?.stepOne || '';
  const stepTwo = data?.stepTwo || '';
  const stepThree = data?.stepThree || '';
  const stepFour = data?.stepFour || '';
  const stepFive = data?.stepFive || '';
  const photoTextLineThree = data?.photoTextLineThree || '';
  const photoTextLineOne = data?.photoTextLineOne || '';
  const photoTextLineTwo = data?.photoTextLineTwo || []
  const photoTextLineTwoA = data?.photoTextLineTwo?.[0]?.text || []
  const photoTextLineTwoB = data?.photoTextLineTwo?.[1]?.text || []
  const photoTextLineTwoC = data?.photoTextLineTwo?.[2]?.text || []

  const titleForScroll = homeData?.titleForScroll || ''

  const elementRef = useRef(null);


  const [finalTextBottom, setFinalTextBottom] = useState<string>(textBottom)

  const scrollHandler = () => {

    elementRef?.current?.scrollIntoView({
      behavior: "smooth",
      // block: "nearest",
      // inline: "start"
    });
  }



  return (
    <ModuleWrapper id="lets-start">
      <ScrollWrapper onClick={()=>scrollHandler()}>
      <ScrollIcon  src={ScrollImg} />
          <ScrollText>{titleForScroll}</ScrollText>
      </ScrollWrapper>

      <TextWrapper>
        <Paragraph className="start">
          {header}
        </Paragraph>
    
        <StepWrapper>
          <Number className="one">1.</Number>
          <Paragraph className="start">{stepOne}</Paragraph>
        </StepWrapper>
        <StepWrapper>
          <Number className="two">2.</Number>
          <Paragraph className="start">{stepTwo}</Paragraph>
        </StepWrapper>
        <StepWrapper>
          <Number className="three">3.</Number>
          <Paragraph className="start">{stepThree}</Paragraph>
        </StepWrapper>
        <StepWrapper>
          <Number className="four">4.</Number>
          <Paragraph className="start">{stepFour}</Paragraph>
        </StepWrapper>
        <StepWrapper>
          <Number className="five">5.</Number>
          <Paragraph className="start">{stepFive}</Paragraph>
        </StepWrapper>

        <TextBottom onMouseEnter={() => setFinalTextBottom(textBottomHover)} onMouseLeave={() => setFinalTextBottom(textBottom)}>{finalTextBottom.replace(/ /g, '')}</TextBottom>
        <div>
          <Paragraph className="start">{mailInfo}</Paragraph>
          <ContactInfo>{mail}</ContactInfo>

          <Paragraph className="start">{phoneInfo}</Paragraph>
          <ContactInfo>{phone}</ContactInfo>

          <Paragraph className="start">{websiteInfo}</Paragraph>
          <ContactInfo>{website}</ContactInfo>

          <Paragraph className="start">{socialInfo}</Paragraph>
          <SocialMediaWrapper>
            <SocialMediaItem as="a" href={linkedinUrl} target="_blank">
              <SocialMediaIcon src={Linkedin} />
            </SocialMediaItem>

            <SocialMediaItem as="a" href={facebookUrl} target="_blank">
              <SocialMediaIcon src={Facebook} />
            </SocialMediaItem>

            <SocialMediaItem as="a" href={instagramUrl} target="_blank">
              <SocialMediaIcon src={Instagram} />
            </SocialMediaItem>
          </SocialMediaWrapper>
        </div>

        <div ref={elementRef}></div>
      </TextWrapper>
      <PhotoWrapper bgImage={photo}>

        <TextPhoto className="white start">{photoTextLineOne}</TextPhoto>
        <TypeWritterText>
        <Typewriter
  options={{
    strings: [photoTextLineTwoA.toString(), photoTextLineTwoB.toString(), photoTextLineTwoC.toString()],
    autoStart: true,
    loop: true,
  }}
/>
        </TypeWritterText>

          <TextPhoto className="white start start-padding">{photoTextLineThree}</TextPhoto>


      
      </PhotoWrapper>
    </ModuleWrapper>
  );
};

export default LetsStart;
