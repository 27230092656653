import * as React from 'react';
import styled from 'styled-components';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';

const Text = styled.div`
  font-size: 16px;
  line-height: 40px;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-family: 'Barlow';
  text-indent: -10%;
  &.start{
    text-indent: 0;
    font-weight: ${({ theme }) => theme.fontWeight.regular};
  }
  @media (max-width: 850px) {
    font-size: 14px;
    line-height: 30px;
  }
  @media (max-width: 550px) {
    text-indent: 0%;
  }

  &.copy{
    @media (max-width: 1800px) {
      overflow-y: auto;
    text-indent: 0%;
    padding-top: 2%;
  }

  }


  .text-big {
    text-indent: 0;
    font-size: 107px;
    line-height: 95px;
    font-weight: ${({ theme }) => theme.fontWeight.extraLight};
    @media (max-width: 1800px) {
      font-size: 75px;
      line-height: 30px;
    }
    @media (max-width: 1440px) {
      font-size: 70px;
      line-height: 40px;
    }
    @media (max-width: 550px) {
      font-size: 59px;
      line-height: 32px;
    }
  }

`;
interface Props {
  children: any;
  className?: string;
}

const Paragraph: React.FC<Props> = ({ children, className }) => (
    <Text className={className} dangerouslySetInnerHTML={getHtmlFromRedactorField(children)} />
  // <Text className={className}>{children}</Text>
);

export default Paragraph;
