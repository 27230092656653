import * as React from 'react';
import { Link} from 'gatsby';
import styled, { keyframes } from 'styled-components';
// import Seo from '../../components/seo';
import ScrollImg from '../../assets/scroll-home.svg';
import LineImg from '../../assets/line-home.svg';
import BlockImg from '../../assets/shape-home.svg';
import TextDown from '../../assets/text-down.svg';
import TextUp from '../../assets/text-up.svg'
import { setActiveSection, setScrollPosition, visibleLogoDescription,hiddenLogoDescription, setActiveTextBottom, setActiveTextBottomHover } from '../../store/menuSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

interface MainTextProps {
  text: string;
}

const MainText = styled.div<MainTextProps>`
  font-family: 'Staatliches';
  font-size: 232px;
  line-height: 200px;
  letter-spacing: 0px;
  color: black;
  text-transform: uppercase;
  text-align: center;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    background-image: url(${BlockImg});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    left: -25%;
    width: 35px;
    height: 30px;
    bottom: 30%;
  }

  .fancy-text {
    position: relative;
    z-index: 101;
    &::before {
      content: '${({ text }) => '_' + text}';
      font-family: 'Staatliches';
      font-size: inherit;
      line-height: inherit;
      letter-spacing: 0px;
      position: absolute;
      z-index: -2;
      color: ${({ theme }) => theme.colors.blue};
      bottom: 10%;
      right: 3%;
    }
    &::after {
      content: '${({ text }) => text}';
      font-family: 'Staatliches';
      font-size: inherit;
      line-height: inherit;
      letter-spacing: 0px;
      position: absolute;
      z-index: -1;
      color: ${({ theme }) => theme.colors.red};
      top: 10%;
      left: 5%;
    }
  }
  @media (max-width: 1440px) {
    font-size: 128px;
    line-height: 110px;

    &::before {
      background-image: url(${BlockImg});
      left: -25%;
      bottom: 30%;
    }

    .fancy-text {
      &::before {
        bottom: 10%;
        right: 3%;
      }
      &::after {
        top: 10%;
        left: 5%;
      }
    }
  }
  @media (max-width: 850px) {
    &::before {
      display: none;
    }
  }

  @media (max-width: 550px) {
    font-size: 70px;
    line-height: 64px;
    /* text-align: right; */
    .fancy-text {
      &::before {
        bottom: 10%;
        right: 3%;
      }
      &::after {
        top: 10%;
        left: 5%;
      }
    }
  }
`;




const MainTextWrapper = styled.div`
align-items: center;
pointer-events: all;
  display: flex;
  flex-direction: column;
  font-family: 'Staatliches';
  font-size: 232px;
  line-height: 200px;
  letter-spacing: 0px;
  color: black;
  text-transform: uppercase;
  text-align: center;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    background-image: url(${BlockImg});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    left: -25%;
    width: 35px;
    height: 30px;
    bottom: 30%;
  }
  @media (max-width: 1600px) {
    font-size: 128px;
    line-height: 110px;

    &::before {
      background-image: url(${BlockImg});
      left: -25%;
      bottom: 30%;
    }
  }
  @media (max-width: 850px) {
    &::before {
      display: none;
    }
  }

  @media (max-width: 550px) {
    font-size: 70px;
    line-height: 64px;
  }
`;

const indexUp = keyframes`
  from {
z-index:80;

  }

  to {
    z-index:990;
  }
`;

const indexDown = keyframes`
  from {
z-index:150;

  }

  to {
    z-index:80;
  }
`;


const TextImageWrapper = styled.div`
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: column;
  &:hover{
    .text-up{
      transform: translateX(7.95%);
    }
  }

&.up-index{
  transition: z-index 0.5s;
z-index: 101;
}
&.down-index{
  z-index: 10;

}
`

const StyledTextImage = styled.img`
width:max-content;
height:auto;
position: relative;
@media (max-width:1600px){
    width:50vw;
}
@media (max-width:1024px){
    width:60vw;
}
@media (max-width:850px){
    width: 100vw;
  }

&.text-up{
  transition: 0.2s all;
  @media (max-width:1600px){
    bottom: -1px;
    width: 43.1vw;
  }
  @media (max-width:1400px){

    width: 43.2vw;
  }
  @media (max-width:1024px){
    width: 51.8vw;
  }

  @media (max-width:850px){
    width: 86.3vw;
  }

 
}
`


const ModuleWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  &.one-page {
    height: 100vh;
    width: 100vw;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    pointer-events: none;
    @media (max-width: 550px) {
    position: fixed;
    width: 100vw;
    height: 100%;
    min-height: -webkit-fill-available;
    overflow-x: hidden;
    /* overflow-y: auto; */
  }

  }
`;

const ScrollWrapper = styled(Link)`
  position: absolute;
  bottom: 5%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  pointer-events: all;
`;

const ScrollIcon = styled.img`
  position: relative;
  margin: 0 auto;
  width: 32px;
  height: 20px;
  /* background-color: ${({ theme }) => theme.colors.blue};
  transform: rotate(45deg); */
  /* &::after {
    content: '';
    width: 32px;
    height: 5px;
    background-color: ${({ theme }) => theme.colors.red};
    transform: rotate(90deg);
    position: absolute;
  } */
`;

const ScrollText = styled.p`
  font-size: 12px;
  line-height: 30px;
  font-family: 'Barlow';
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  margin-top: 8px;
`;

const LineElement = styled.img`
  width: max-content;
  height: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  @media (max-width: 1200px) {
    height: 5px;
    bottom: -1px;
  }
  @media (max-width: 550px) {
    /* width: 100%;
    height: 5px; */
  }
`;

interface ComponentProps {
  data:any;
  aboutData:any
}

const Home: React.FC<ComponentProps> = ({data,aboutData}) => {

  const dispatch = useAppDispatch();
  const activeSection = useAppSelector((state) => state.menu.activeSection)

  const clickHandler = (name:string) =>{
    dispatch(visibleLogoDescription())
    dispatch(setScrollPosition('about'))
    dispatch(setActiveSection(name))
    dispatch(setActiveTextBottom(aboutData?.textBottom))
    dispatch(setActiveTextBottomHover(aboutData?.textBottomHover))
  }
  const titleForScroll = data?.titleForScroll || ''
    const textVisibility = true;
  
  return (
    <ModuleWrapper className="one-page">
      {/* <MainText text="Dimension">
        A DIFFERENT
        <br />
        <span className="fancy-text">Dimension</span> <br /> of design
      </MainText> */}

      <MainTextWrapper>
        <p> A DIFFERENT</p>
        <TextImageWrapper className={activeSection === 'home' ? 'up-index' : 'down-index'}>
          <StyledTextImage className="text-up" src={TextUp}/>
          <StyledTextImage  src={TextDown}/>
        </TextImageWrapper>

        <p> of design</p>

      </MainTextWrapper>

      <ScrollWrapper to="#about" onClick={() => clickHandler('about')}>
        <ScrollIcon src={ScrollImg} />
        <ScrollText>{titleForScroll}</ScrollText>
      </ScrollWrapper>

      <LineElement src={LineImg} />
    </ModuleWrapper>
  );
};

export default Home;
