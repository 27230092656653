import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Paragraph from '../typography/Paragraph';
import photoTest from '../../assets/images/photo-factor.jpg';
import photoHover from '../../assets/images/gallery2.jpg';
import TextPhoto from '../typography/TextPhoto';
import CrossImg from '../../assets/x-shape.svg'

import { useAppSelector, useAppDispatch } from '../../store/hooks';
import Gallery from '../molecules/Gallery';
import { toggleIsGalleryFactorX } from '../../store/menuSlice';

const ModuleWrapper = styled.div`
  overflow: hidden;
  background-color: white;
  z-index: 102;
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 4fr 3fr;
  position: relative;
  @media (max-width: 1440px) {
    grid-template-columns: 6fr 3fr;
  }
  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  @media (max-width: 550px) {
    min-height: 100vh;
    height: auto;
  }
`;

const TextWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 20vh 120px 0;
  display: flex;
  justify-content: center;
  @media (max-width: 1800px) {
    overflow-y: auto;
    padding: 20vh 100px 30vh;
  }
  @media (max-width: 1440px) {
    padding: 15vh 100px 30vh;
  }

  @media (max-width: 1200px) {
    padding: 15vh 20vw 14vh;
  }
  @media (max-width: 550px) {
    padding: 17vh 30px 10vh;
  }
`;

interface PhotoWrapperProps {
  bgImage: string,
  bgHover: string,
}

const rotateColor = keyframes`
  from {
    transform: rotate(0deg);
    background:black;
  }

  to {
    transform: rotate(180deg);
    background: #01FFFF;
  }
`;

const PhotoWrapper = styled.div<PhotoWrapperProps>`
  width: 100%;
  height: 100%;
  background: black;
  background-size: cover;
  background-position: center;
  /* transition: all 0.3s ease-in-out; */
  background-image: url('${({ bgImage }) => bgImage}');
  min-height: 200px;
  position: relative;
  overflow:hidden;
  cursor:pointer;
  &:hover{
    background-image: url('${({ bgHover }) => bgHover}');
    div{
      opacity:1;
      @media (max-width:550px){
        opacity:0;
      }
    }
    &::after{
      position:absolute;
      content:'';
      width:20px;
      height:6px;
      background:black;
      top:0;
      bottom:0;
      left:0;
      right:0;
      margin:auto;
      z-index: 10;
      animation: ${rotateColor} 0.5s 0.2s both;
    }
    &::before{
      position:absolute;
      content:'';
      width:6px;
      height:20px;
      background:black;
      top:0;
      bottom:0;
      left:0;
      right:0;
      margin:auto;
      z-index: 10;
      animation: ${rotateColor} 0.5s 0.2s both;
    }
    
  }
  @media (max-width: 1200px) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

`;


const TextBottom = styled.p`
  font-family: 'Staatliches';
  position: absolute;
  font-size: 200px;
  line-height: 200px;
  bottom: -35px;
  left: 0;
  color: black;
  width: 100%;
  transition: all 0.3 ease-in-out;
  display: inline-block;
  width: auto;
  display:none;
  @media (max-width: 1440px) {
    font-size: 141px;
    line-height: 124px;
    bottom: -16px;
  }
  @media (max-width: 1200px) {
    bottom: -13px;
    color: white;
    display:none;
    display: inline-block;
  }
  @media (max-width: 550px) {
    
    width: inherit;
    font-size: 75px;
    line-height: 66px;
    position: relative;
    color: black;
    bottom: -8px;
  }

  &:hover {
    /* transform: matrix(-1, 0, 0, 1, 0, 0); */
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
    @media (max-width: 1200px) {
      -webkit-text-stroke-color: white;
    }
    @media (max-width: 550px) {
      -webkit-text-stroke-color: black;
    }
  }
`;

const Line = styled.div`
  width: 5px;
  height: 20vh;
  background-color: ${({ theme }) => theme.colors.purple};
  right: 0;
  position: absolute;
  top: 60vh;
  z-index: 10;
`;

const CrossImage = styled.div`
background-image:url(${CrossImg});
background-repeat:no-repeat;
background-position:center;
background-size:cover;

position:absolute;
opacity:0;
width: 100%;
    height: inherit;

`

interface ComponentProps {
  data: any
}

const FactorX: React.FC<ComponentProps> = ({ data }) => {

  const dispatch = useAppDispatch();
  const isGalleryFactorX = useAppSelector((state) => state.menu.isGalleryFactorX)


  const description = data?.description || '';
  const photo = data?.photo?.url || '';
  const photoHover = data?.photoHover?.url || '';
  const gallery = data?.gallery || []
  const textBottom = data?.textBottom || '';
  const textBottomHover = data?.textBottomHover || '';

  const [finalTextBottom, setFinalTextBottom] = useState<string>(textBottom)

  return (
    <>

      {isGalleryFactorX && (
        <Gallery photos={gallery} />
      )}
      <ModuleWrapper id="factor-x">

        <TextWrapper>
          <Paragraph className="copy">
            {description}
          </Paragraph>
        </TextWrapper>
        <PhotoWrapper onClick={() => dispatch(toggleIsGalleryFactorX())} bgHover={photoHover} bgImage={photo}>

          <CrossImage />

        </PhotoWrapper>
        {/* <TextBottom>{textBottom}</TextBottom> */}
        <TextBottom onMouseEnter={() => setFinalTextBottom(textBottomHover)} onMouseLeave={() => setFinalTextBottom(textBottom)}>{finalTextBottom.replace(/ /g, '')}</TextBottom>
      </ModuleWrapper>

    </>
  );
};

export default FactorX;
