import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';

const typing = keyframes`
  from {
    width:0;
  }

  to {
    width:100%;
  }
`;







const Text = styled.div`
  font-size: 50px;
  line-height: 67px;
  font-weight: ${({ theme }) => theme.fontWeight.extraLight};
  font-family: 'Barlow', 'Oswald';
  padding-top: 23vh;
  height: 100%;
  text-align: left;
  transition: text-align 0.3s ease-in-out;
  @media (max-width: 1440px) {
    font-size: 21px;
    line-height: 33px;
  }
  @media (max-width: 1200px) {
    padding-top: 0;
    width: 50%;
    height: max-content;
  }

  @media (max-width: 850px) {
  }

  @media (max-width: 550px) {
    width: 80%;
    font-size: 19px;
    line-height: 29px;
  }

  &.start{
    height: auto;
    font-size:90px;
    line-height: 94px;
    @media (max-width: 1440px) {
      font-size: 72px;
      line-height: 80px;
    }
    
  }
  &.start-padding{
    padding-top:0;
  }

  &.stand-out{
    font-size: 25px;
    line-height: 33px;
    font-weight: ${({ theme }) => theme.fontWeight.extraBold};
    transition: all 0.3s;
    opacity:0;
    width:50%;
    padding-top:45vh;
    text-align: left;
    @media (max-width:1200px){
      padding-top:0vh;
    }
    ;
  }
`;
interface Props {
  children: any;
  className?: string;
}

const Paragraph: React.FC<Props> = ({ children, className }) => (
  <Text className={className} dangerouslySetInnerHTML={getHtmlFromRedactorField(children)} />
  // <Text className={className}>{children}</Text>
);

export default Paragraph;
