import * as React from 'react';
import styled from 'styled-components';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';
import Number1 from '../../assets/numbers/number1.svg';
import Number2 from '../../assets/numbers/number2.svg';
import Number3 from '../../assets/numbers/number3.svg';
import Number4 from '../../assets/numbers/number4.svg';
import Number5 from '../../assets/numbers/number5.svg';

const Text = styled.div`
  font-size: 74px;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-family: 'Staatliches';
  margin-right:16px;
    color:black;
    position: relative;
    /* transition: all 0.3s; */
    &.one{
        &:hover{
            color:white;
            &::after{
                content:'';
                position: absolute;
                background-image:url(${Number1});
                right:0;
                width: 107px;
                height: 76px;
                top:0;
                bottom:0;
            }
        }
    }
    &.two{
        &:hover{
            color:white;
            &::after{
                content:'';
                position: absolute;
                background-image:url(${Number2});
                right:0;
                width: 127px;
                height: 76px;
                top:0;
                bottom:0;
            }
        }
    }
    &.three{
        &:hover{
            color:white;
            &::after{
                content:'';
                position: absolute;
                background-image:url(${Number3});
                right:0;
                width: 127px;
                height: 76px;
                top:0;
                bottom:0;
            }
        }
    }
    &.four{
        &:hover{
            color:white;
            &::after{
                content:'';
                position: absolute;
                background-image:url(${Number4});
                right:0;
                width: 127px;
                height: 76px;
                top:0;
                bottom:0;
            }
        }
    }
    &.five{
        &:hover{
            color:white;
            &::after{
                content:'';
                position: absolute;
                background-image:url(${Number5});
                right:0;
                width: 127px;
                height: 76px;
                top:0;
                bottom:0;
            }
        }
    }
  /* @media (max-width: 850px) {
    font-size: 14px;
    line-height: 30px;
  }
  @media (max-width: 550px) {
    text-indent: 0%;
  } */



`;
interface Props {
  children: any;
  className?: string;
}

const Number: React.FC<Props> = ({ children, className }) => (
  //   <Text className={className} dangerouslySetInnerHTML={getHtmlFromRedactorField(children)} />
  <Text className={className}>{children}</Text>
);

export default Number;
