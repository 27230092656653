import * as React from 'react';
import styled from 'styled-components';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';
import Number1 from '../../assets/numbers/number1.svg';

const Text = styled.div`
  font-size: 32px;
  line-height: 24px;
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-family: 'Barlow';
  margin-bottom:40px;

    color:black;
    position: relative;


  @media (max-width: 550px) {
    font-size: 24px;
  line-height: 18px;
  } 



`;
interface Props {
  children: any;
  className?: string;
}

const Number: React.FC<Props> = ({ children, className }) => (
    <Text className={className} dangerouslySetInnerHTML={getHtmlFromRedactorField(children)} />
  // <Text className={className}>{children}</Text>
);

export default Number;
