import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { closeIsGalleryFactorX, closeIsGalleryWhyUs,  } from '../../store/menuSlice';
import { useAppDispatch } from '../../store/hooks';
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ModuleWrapper = styled.div`
  
  width: auto;
  /* height: 100vh; */
  min-height: 100vh;
  min-height: -webkit-fill-available;
  position:fixed;
  z-index:9999999999;
  /* top:0; */
  left:0;
  bottom:0;
  right:0;
  margin:auto;
  background-color:black;
`;

const PhotoWrapper = styled.div`
height:100vh;
width:auto;
/* display: flex;
justify-content: center;
align-items: center; */
img{
    height:100vh;
    object-fit: cover;
}
`

const Description = styled.div`
display:table;
position: absolute;
    bottom: 40px;
    right: 40px;
    th{
        border:1px solid white;
        padding:10px;
        color:white;
        font-weight:${({theme}) =>theme.fontWeight.extraLight};
        font-family:'Barlow';
    }
`

const CloseWrapper = styled.div`
  opacity:1;
  margin:auto;
  position:absolute;
  z-index: 101;
  top:0;
  bottom:0;
  right:0;
  left:0;
  width:50vw;
  height:50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width:550px){
    width:50vw;
    height:50vh;
  }
  &:hover{
    div{
      opacity:1;
    }
  }
`

const Close= styled.div`
opacity:0;
  background-color:transparent;
  width:80px;
  height:80px;
  border-radius:100px;
  border:2px solid white;
  margin:auto;
  position:relative;
  top:0;
  bottom:0;
  right:0;
  left:0;
  cursor: pointer;
  &::after{
    content:'';
    width: 15px;
    height: 5px;
    transform:rotate(-45deg);
    background-color: white;
    display: inline-block;
    position: relative;
    left: -8px;
    top: 26px;
    bottom:0;
  }
  &::before{
    content:'';
    width: 15px;
    height: 5px;
    transform:rotate(45deg);
    background-color: white;
    display: inline-block;
    border:0;
    position:relative;
    left:7px;
    top:26px;
    bottom:0;

  }


`

interface ComponentProps {
photos?:any,
}

const Gallery: React.FC<ComponentProps> = ({photos}) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
      const close = (e) => {
        if(e.keyCode === 27){
          dispatch(closeIsGalleryWhyUs())
          dispatch(closeIsGalleryFactorX())
        }
      }
      window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  },[])

    const closeHandler =() =>{
      dispatch(closeIsGalleryWhyUs())
      dispatch(closeIsGalleryFactorX())
    }

  return (
    <ModuleWrapper>
           <Carousel showArrows showThumbs={false} showIndicators={false} showStatus={false} useKeyboardArrows={true}>
             {photos.map((item) =>{

               const description = item?.description || '';
               const country = item?.country || '';
               const year = item?.year || '';
               const image = getImage(item.photo)

                return(

                  <PhotoWrapper>
                
                  <GatsbyImage image={image} alt="xd" />
                 
                  <CloseWrapper>
                  <Close onClick={()=> closeHandler()}/>
                  </CloseWrapper>
                  <Description>
                  <thead>
                  <tr>
                  <th>{description}</th>
                  <th>{country}</th>
                  <th>{year}</th>
                  </tr>
                  </thead>
                  </Description>
                </PhotoWrapper>
                )
                            })}



            </Carousel>
    </ModuleWrapper>
  );
};

export default Gallery;
